import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../../components/seo";
import styled, { keyframes, css } from "styled-components";
import {
  mainWhite,
  mainBlack,
  mainGray,
  graphik,
  larsseit,
  amatic,
  screen,
  transHover,
} from "../../components/common/variables";
import { Waypoint } from "react-waypoint";
import ScrollHint from "../../components/scroll-hint";
import LabeledArrow from "../../components/labeled-arrow";
import ContactForm from "../../components/contact-form";
import imgPrint from "../../images/case-studies/australian-thermic-lance/handsome-creative-ATLC-print-design.jpg";
import LogoProcessLg from "../../images/case-studies/australian-thermic-lance/svg/atlc-logo-progress-lg.svg";
import LogoProcessSm from "../../images/case-studies/australian-thermic-lance/svg/atlc-logo-progress-sm.svg";
import Slider from "react-slick";
import interactiveSlide1 from "../../images/case-studies/australian-thermic-lance/handsome-creative-ATLC-print-refresh.jpg";
import interactiveSlide2 from "../../images/case-studies/australian-thermic-lance/handsome-creative-ATLC-print-original.jpg";
import ArrowSideToSide from "../../images/case-studies/australian-thermic-lance/svg/arrow-side-to-side.svg";
import ArrowCurved from "../../images/case-studies/source-separation-systems/svg/curved-arrow.svg";
import gifPhone from "../../images/case-studies/australian-thermic-lance/australian-thermic-lance-company-mobile.gif";
import LogoATLC from "../../images/case-studies/australian-thermic-lance/svg/logo-colour.svg";
import videoLogo from "../../videos/australian-thermic-lance-logo-update.mp4";
import seoGif from "../../images/case-studies/australian-thermic-lance/australian-thermic-lance-company-tablet.gif";
import carouselGif2 from "../../images/case-studies/australian-thermic-lance/thermiclance-what-is-thermic-lance.gif";
import carouselGif3 from "../../images/case-studies/australian-thermic-lance/thermiclance-lance-products.gif";

const scaleIn = keyframes`
0% {
  transform: scale(0.97);
}
100% {
  transform: scale(1.6);
}
`;

const Wrapper = styled.div`
  color: ${mainBlack};
  font-family: ${graphik};
  position: relative;

  .main {
    padding: 0 26px;
    @media ${screen.xsmall} {
      padding: 0 60px;
    }
    @media ${screen.small} {
      padding: 0 80px;
    }
    @media ${screen.medium} {
      padding: 0 7vw;
    }

    &__heading {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.17;
      margin-bottom: 5px;
      @media ${screen.medium} {
        font-size: clamp(1.1rem, calc(0.88rem + 1vw), 2.1rem);
      }
    }

    &__subheading {
      color: ${mainGray};
      font-size: 1.1rem;
      font-style: italic;
      margin-bottom: 30px;
      @media ${screen.small} {
        margin-bottom: 40px;
      }
      @media ${screen.medium} {
        font-size: 1.33rem;
      }
    }

    &__description {
      max-width: 962px;

      p {
        font-size: 1.1rem;
        margin-bottom: 34px;
        @media ${screen.small} {
          font-size: 1.15rem;
          margin-bottom: 40px;
        }
        @media ${screen.medium} {
          font-size: 1.33rem;
        }
      }
    }

    &__web-link {
      color: ${mainGray};
      display: inline-block;
      font-size: 1.1rem;
      margin: 0 0 100px 0;
      transition: ${transHover};
      @media ${screen.xsmall} {
        margin: 20px 0 130px 0;
      }
      @media ${screen.small} {
        font-size: 1.15rem;
        margin: 32px 0 175px 0;
      }
      @media ${screen.medium} {
        font-size: 1.33rem;
      }

      @media ${screen.withCursor} {
        &:hover {
          color: #00235d;
        }
      }
    }
  }

  .top-featured-img {
    margin: 0 -75px;
    background: #f4f4f4;
    overflow: hidden;
    @media ${screen.xsmall} {
      margin: 0;
    }

    .img-subwrapper {
      transform: scale(0.97);
      animation: ${(props) =>
        props.isTopImg
          ? css`
              ${scaleIn} 6s ease-out forwards
            `
          : `none`};

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .branding {
    &__flex {
      display: flex;
      flex-direction: column;

      @media ${screen.xsmall} {
        flex-direction: row;
      }

      .col {
        background: #00235d;
        @media ${screen.xsmall} {
          width: 50%;
          background: #0a296b;
        }

        &--video {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 300px;
          @media ${screen.xsmall} {
            height: auto;
          }

          video {
            display: block;
            width: 200px;
            height: 100%;
            outline: none;
            border: none;
            -webkit-mask-image: -webkit-radial-gradient(white, black);
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            @media ${screen.xsmall} {
              width: 250px;
            }
            @media ${screen.small} {
              width: 350px;
            }
            @media ${screen.medium} {
              width: 370px;
            }
            @media ${screen.large} {
              width: 400px;
            }
          }

          p {
            color: #ffffff;
          }
        }

        &--text {
          background: #a6a7a9;
          color: #ffffff;
          padding: 60px 26px;
          @media ${screen.xsmall} {
            padding: 110px 70px;
          }
          @media ${screen.small} {
            padding: 170px 90px;
          }
          @media ${screen.medium} {
            padding: 170px 100px;
          }
          @media ${screen.large} {
            padding: 250px 150px;
          }

          .heading {
            font-weight: 500;
            line-height: 1.17;
            font-size: 2.3rem;
            font-family: ${larsseit};
            margin: 0 0 20px 0;
            @media ${screen.xsmall} {
            }
            @media ${screen.small} {
              font-size: 2.9rem;
            }
            @media ${screen.medium} {
              font-size: 3rem;
            }
            @media ${screen.xlarge} {
              margin: 0 0 30px 0;
            }
          }

          .description {
            font-size: 1.1rem;
            @media ${screen.small} {
              font-size: 1.15rem;
            }
            @media ${screen.medium} {
              font-size: 1.33rem;
            }
          }
        }
      }
    }
  }

  .logo {
    padding: 60px 26px;
    @media ${screen.xsmall} {
      padding: 60px 60px;
    }
    @media ${screen.small} {
      padding: 75px 80px;
    }
    @media ${screen.medium} {
      padding: 110px 7vw;
    }

    .lg {
      display: none;
      @media ${screen.xsmall} {
        display: block;
      }
    }

    .sm {
      display: block;
      @media ${screen.xsmall} {
        display: none;
      }
    }
  }

  .site-carousel {
    position: relative;

    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            .each-slide {
              color: #ffffff;
              outline: none;
              @media ${screen.xsmall} {
                padding: 0 60px;
              }
              @media ${screen.small} {
                padding: 0 80px;
              }
              @media ${screen.medium} {
                padding: 0 13vw;
              }

              .heading {
                font-weight: 500;
                line-height: 1.17;
                font-size: 2.3rem;
                font-family: ${larsseit};
                margin: 0 0 20px 0;
                @media ${screen.small} {
                  font-size: 2.9rem;
                }
                @media ${screen.medium} {
                  font-size: 3rem;
                }
                @media ${screen.xlarge} {
                  margin: 0 0 30px 0;
                }
              }

              .description {
                font-size: 1.1rem;
                @media ${screen.small} {
                  font-size: 1.15rem;
                }
                @media ${screen.medium} {
                  font-size: 1.33rem;
                }
              }

              &--one {
                background: #f4f4f4;
                @media ${screen.xsmall} {
                  background: linear-gradient(
                    to right,
                    #f4f4f4 63%,
                    #00235d 37%
                  );
                }

                .flex {
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  height: 650px;
                  @media ${screen.xsmall} {
                    align-items: center;
                    justify-content: flex-start;
                    flex-direction: row;
                    height: 950px;
                  }

                  &__logo {
                    position: relative;
                    max-width: 210px;
                    margin: 0 14px 0 auto;
                    @media ${screen.xsmall} {
                      width: 43%;
                      margin: 0;
                      max-width: none;
                    }

                    svg {
                      display: block;
                      max-width: 210px;
                      width: 100%;
                      @media ${screen.xsmall} {
                        max-width: 242px;
                      }
                      @media ${screen.small} {
                        max-width: 400px;
                        margin: 0 auto;
                      }
                    }

                    .pointer {
                      position: absolute;
                      display: flex;
                      left: -107px;
                      top: 0;
                      @media ${screen.xsmall} {
                        top: -130px;
                        right: 60px;
                        left: auto;
                      }
                      @media ${screen.small} {
                        top: -100px;
                        right: 150px;
                      }
                      @media ${screen.medium} {
                        right: 160px;
                        left: auto;
                      }

                      @media ${screen.large} {
                        top: -110px;
                        right: 180px;
                      }
                      @media ${screen.xlarge} {
                        top: -115px;
                        right: 255px;
                      }

                      svg {
                        width: 40px;
                        transform: rotate(145deg);
                        display: block;
                        position: absolute;
                        right: 9px;
                        fill: #00235d;
                        top: 42px;
                        @media ${screen.xsmall} {
                          transform: rotate(-110deg);
                          width: 57px;
                          top: 23px;
                          left: -92px;
                          right: auto;
                        }
                      }

                      p {
                        color: #00235d;
                        font-size: 1.2rem;
                        font-weight: 700;
                        line-height: 1.3;
                        text-transform: uppercase;
                        font-family: ${amatic};
                        @media ${screen.xsmall} {
                          font-size: 1.55rem;
                        }
                      }
                    }
                  }

                  &__gif {
                    @media ${screen.xsmall} {
                      width: 45%;
                    }
                    @media ${screen.small} {
                      width: 57%;
                    }

                    .gif-subwrapper {
                      position: relative;
                      @media ${screen.xsmall} {
                        margin: 0 -70px 0 0;
                      }
                      @media ${screen.small} {
                        margin: 0;
                      }

                      .pointer {
                        position: absolute;
                        display: flex;
                        right: 13px;
                        bottom: 130px;
                        @media ${screen.xsmall} {
                          display: none;
                        }
                        @media ${screen.medium} {
                          right: -110px;
                          bottom: 100px;
                          display: flex;
                        }
                        @media ${screen.large} {
                          right: -65px;
                          bottom: 90px;
                        }
                        @media ${screen.xlarge} {
                          right: -55px;
                          bottom: 55px;
                        }

                        svg {
                          width: 40px;
                          transform: rotate(-10deg);
                          display: block;
                          position: absolute;
                          fill: #00235d;
                          top: -71px;
                          left: 25px;
                          @media ${screen.xsmall} {
                            fill: #ffffff;
                            width: 57px;
                            top: -110px;
                            left: 30px;
                          }
                        }

                        p {
                          color: #00235d;
                          font-size: 1.2rem;
                          font-weight: 700;
                          line-height: 1.3;
                          text-transform: uppercase;
                          font-family: ${amatic};
                          @media ${screen.xsmall} {
                            color: #ffffff;
                            font-size: 1.55rem;
                          }
                        }
                      }

                      img {
                        display: block;
                        width: 100%;
                        max-width: 215px;
                        margin: 0 auto 0 0;
                        @media ${screen.xsmall} {
                          max-width: 420px;
                          margin: 0 auto;
                        }
                      }
                    }
                  }
                }
              }

              &--two {
                background: #00235d;
                @media ${screen.xsmall} {
                  background: linear-gradient(
                    to right,
                    #00235d 63%,
                    #f2993f 37%
                  );
                }

                .flex {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  height: 650px;
                  padding: 0 26px;
                  @media ${screen.xsmall} {
                    height: 950px;
                    align-items: center;
                    flex-direction: row;
                    padding: 0;
                    justify-content: flex-start;
                  }

                  &__text {
                    @media ${screen.xsmall} {
                      margin: 0 50px 0 0;
                    }

                    @media ${screen.xsmall} {
                      width: 43%;
                    }

                    .heading {
                      max-width: 450px;
                    }

                    .description {
                      max-width: 400px;
                      display: none;
                      @media ${screen.xsmall} {
                        display: block;
                      }
                    }
                  }

                  &__img {
                    @media ${screen.xsmall} {
                      width: 57%;
                    }

                    .img-subwrapper {
                      max-width: 270px;
                      margin: 20px 0 0 0;
                      @media ${screen.xsmall} {
                        max-width: 560px;
                        margin: 0;
                      }

                      img {
                        display: block;
                        width: 100%;
                      }
                    }
                  }
                }
              }

              &--three {
                background: #f2993f;
                @media ${screen.xsmall} {
                  background: linear-gradient(
                    to right,
                    #f2993f 63%,
                    #f4f4f4 37%
                  );
                }

                .flex {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  height: 650px;
                  padding: 0 26px;
                  @media ${screen.xsmall} {
                    height: 950px;
                    align-items: center;
                    flex-direction: row;
                    justify-content: flex-start;
                    padding: 0;
                  }

                  &__text {
                    @media ${screen.xsmall} {
                      width: 43%;
                      margin: 0 50px 0 0;
                    }

                    .heading {
                      max-width: 470px;
                    }

                    .description {
                      max-width: 470px;
                      display: none;
                      @media ${screen.xsmall} {
                        display: block;
                      }
                    }
                  }

                  &__img {
                    @media ${screen.xsmall} {
                      width: 57%;
                    }

                    .img-subwrapper {
                      margin: 10px 0 0 0;
                      position: relative;
                      max-width: 270px;
                      @media ${screen.xsmall} {
                        max-width: 560px;
                        margin: 0;
                      }

                      img {
                        display: block;
                        width: 100%;
                      }

                      .pointer {
                        display: none;
                        @media ${screen.medium} {
                          right: -180px;
                          display: flex;
                          bottom: 55px;
                          position: absolute;
                        }
                        @media ${screen.large} {
                          right: -200px;
                        }
                        @media ${screen.xlarge} {
                          right: -245px;
                        }

                        svg {
                          width: 40px;
                          transform: rotate(-10deg);
                          display: block;
                          position: absolute;
                          right: 0;
                          fill: #00235d;
                          @media ${screen.xsmall} {
                            width: 57px;
                            top: -112px;
                            left: 23px;
                          }
                        }

                        p {
                          color: #00235d;
                          font-size: 1.2rem;
                          font-weight: 700;
                          line-height: 1.3;
                          text-transform: uppercase;
                          font-family: ${amatic};
                          @media ${screen.xsmall} {
                            font-size: 1.55rem;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .dots {
      display: flex;
      position: absolute;
      bottom: 26px;
      left: 50%;
      transform: translateX(-50%);
      @media ${screen.xsmall} {
        bottom: 45px;
      }
      @media ${screen.medium} {
      }

      &__dot {
        height: 4px;
        width: 49px;
        background: ${(props) =>
          props.isFirstSlide
            ? "rgba(0,35,93, 0.5)"
            : "rgba(255, 255, 255, 0.5)"};
        display: block;
        margin-right: 13px;
        transition: background 0.1s ease-out;
        cursor: pointer;
        @media ${screen.medium} {
          width: 65px;
          width: calc(26px + 2vw);
          height: 6px;
          margin-right: 22px;
        }

        &--active {
          background: ${(props) =>
            props.isFirstSlide ? "#00235D" : "#ffffff"};
        }
      }
    }
  }

  .seo {
    &__flex {
      display: flex;
      flex-direction: column;
      position: relative;
      background: #f2993f;
      @media ${screen.xsmall} {
        flex-direction: row;
        background: linear-gradient(to right, #f2993f 63%, #f4f4f4 37%);
      }

      .col {
        display: flex;
        justify-content: center;
        align-items: center;
        @media ${screen.xsmall} {
          width: 50%;
          min-height: 500px;
        }
        @media ${screen.small} {
          min-height: 700px;
        }
        @media ${screen.medium} {
          min-height: 900px;
        }

        &--text {
          color: #ffffff;
          padding: 60px 26px 20px 26px;
          text-align: center;
          @media ${screen.xsmall} {
            text-align: left;
            padding: 60px;
            width: 50%;
          }
          @media ${screen.small} {
            padding: 0;
          }

          .heading {
            font-weight: 500;
            line-height: 1.17;
            font-size: 2.3rem;
            font-family: ${larsseit};
            max-width: 468px;
            @media ${screen.small} {
              font-size: 2.9rem;
            }
            @media ${screen.medium} {
              font-size: 3rem;
            }
          }
        }

        &--gif {
          img {
            display: block;
            width: 100%;
            padding: 0 11px;
            @media ${screen.xsmall} {
              max-width: calc(100px + 33vw);
              right: 43px;
              position: absolute;
              padding: 0;
              bottom: 0;
            }
            @media ${screen.small} {
              max-width: 570px;
            }
            @media ${screen.medium} {
              max-width: 640px;
              right: 60px;
            }
            @media ${screen.large} {
              max-width: 740px;
              right: 100px;
            }
            @media ${screen.xlarge} {
              max-width: 791px;
              right: 200px;
            }
          }
        }
      }
    }

    &__url {
      background: #00235d;
      text-align: center;
      padding: 16px 0 15px 0;
      @media ${screen.xsmall} {
        padding: 25px 0 24px 0;
      }

      a {
        color: #fff;
        font-weight: 500;
        line-height: 1.17;
        font-size: 1.75rem;
        display: inline-block;
        font-family: ${larsseit};
        transition: ${transHover};
        @media ${screen.small} {
          font-size: 2.9rem;
        }
        @media ${screen.medium} {
          font-size: 3rem;
        }

        @media ${screen.withCursor} {
          &:hover {
            color: #f2993f;
          }
        }
      }
    }
  }

  .flyer {
    padding: 60px 26px;
    position: relative;
    @media ${screen.xsmall} {
      padding: 60px 60px;
      min-height: 880px;
    }
    @media ${screen.small} {
      padding: 70px 80px;
    }
    @media ${screen.medium} {
      padding: 90px 100px 90px 7vw;
    }
    @media ${screen.large} {
      padding: 110px 100px 90px 7vw;
    }
    @media ${screen.xlarge} {
      min-height: 1080px;
    }

    &__flex {
      display: flex;
      flex-direction: column;

      .col {
        @media ${screen.small} {
          width: 50%;
        }

        &--text {
          display: flex;
          @media ${screen.xsmall} {
            padding: 70px 0 0 0;
          }
          @media ${screen.small} {
            padding: 150px 0 0 0;
          }
          @media ${screen.large} {
            justify-content: center;
            padding: 170px 0 0 0;
          }
          @media ${screen.xlarge} {
            padding: 270px 0 0 0;
          }

          .text-subwrapper {
            max-width: 600px;
            @media ${screen.small} {
              max-width: 410px;
            }
            @media ${screen.large} {
              max-width: 590px;
            }
            @media ${screen.xlarge} {
              max-width: 650px;
            }

            .heading {
              font-weight: 500;
              line-height: 1.17;
              font-size: 2.3rem;
              font-family: ${larsseit};
              margin: 0 auto 20px auto;
              @media ${screen.small} {
                font-size: 2.9rem;
              }
              @media ${screen.medium} {
                font-size: 3rem;
              }
              @media ${screen.xlarge} {
                margin: 0 auto 30px auto;
              }
            }

            .description {
              font-size: 1.1rem;
              @media ${screen.small} {
                font-size: 1.15rem;
              }
              @media ${screen.medium} {
                font-size: 1.33rem;
              }
            }
          }
        }

        &--slide-img {
          @media ${screen.small} {
            position: absolute;
            top: calc(50% + 50px);
            transform: translateY(-50%);
            right: 80px;
            width: 580px;
          }
          @media ${screen.medium} {
            right: 110px;
            width: 590px;
          }
          @media ${screen.xlarge} {
            width: 770px;
          }

          .slide-wrapper {
            margin: 40px 0 0 0;
            @media ${screen.xsmall} {
              margin: 50px 0 0 0;
              width: 500px;
              height: 500px;
            }
            @media ${screen.small} {
              position: relative;
              overflow: hidden;
              margin: 0;
            }
            @media ${screen.medium} {
              width: 590px;
              height: 590px;
            }
            @media ${screen.xlarge} {
              width: 770px;
              height: 770px;
            }

            .img {
              display: block;
              top: 0;
              height: 100%;
              background-repeat: no-repeat;
              background-size: 770px;
              position: absolute;
              @media ${screen.xsmall} {
                background-size: 500px;
              }
              @media ${screen.medium} {
                background-size: 640px;
              }
              @media ${screen.xlarge} {
                background-size: 770px;
              }

              &--one {
                right: 0;
                z-index: 1;
                background-image: url(${interactiveSlide2});
                width: 770px;
                @media ${screen.xsmall} {
                  width: 500px;
                }
                @media ${screen.medium} {
                  width: 590px;
                }
                @media ${screen.xlarge} {
                  width: 770px;
                }
              }

              &--two {
                z-index: 2;
                background-image: url(${interactiveSlide1});
                transition: width 0.1s ease;
                right: 0;
                max-width: 770px;
                @media ${screen.xsmall} {
                  width: ${(props) =>
                    props.isMouseOver ? `${props.imgWidth - 60}px` : `260px`};
                }
                @media ${screen.small} {
                  width: ${(props) =>
                    props.isMouseOver ? `${props.imgWidth - 80}px` : `310px`};
                }
                @media ${screen.medium} {
                  width: ${(props) =>
                    props.isMouseOver ? `${props.imgWidth - 110}px` : `347px`};
                }
                @media ${screen.large} {
                  width: ${(props) =>
                    props.isMouseOver
                      ? `${props.imgWidth - 110}px`
                      : `${props.clientX}px`};
                }
              }
            }

            .lined-arrow {
              width: 12px;
              background: #fff;
              height: 100%;
              position: absolute;
              top: 0;
              transition: right 0.1s ease;
              z-index: 3;
              @media ${screen.xsmall} {
                right: ${(props) =>
                  props.isMouseOver
                    ? `${props.imgWidth - 60 - 12}px`
                    : `248px`};
              }
              @media ${screen.small} {
                right: ${(props) =>
                  props.isMouseOver
                    ? `${props.imgWidth - 80 - 12}px`
                    : `298px`};
              }
              @media ${screen.medium} {
                right: ${(props) =>
                  props.isMouseOver
                    ? `${props.imgWidth - 110 - 12}px`
                    : `335px`};
              }

              @media ${screen.large} {
                right: ${(props) =>
                  props.isMouseOver
                    ? `${props.imgWidth - 110 - 12}px`
                    : `${props.clientX - 12}px`};
              }

              svg {
                width: 75px;
                height: auto;
                display: block;
                position: absolute;
                top: calc(50% - 20px);
                left: 50%;
                transform: translate(-50%);
                @media ${screen.xsmall} {
                  width: 60px;
                }
                @media ${screen.small} {
                  width: 65px;
                }
                @media ${screen.medium} {
                  width: 70px;
                }
                @media ${screen.xlarge} {
                  width: 75px;
                }
              }
            }

            &--interactive {
              display: none;
              @media ${screen.small} {
                display: block;
              }
            }

            &--static {
              max-width: 800px;
              @media ${screen.small} {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .print {
    padding: 40px 26px;
    @media ${screen.xsmall} {
      padding: 60px 60px;
    }
    @media ${screen.small} {
      padding: 75px 80px;
    }
    @media ${screen.medium} {
      padding: 110px 7vw;
    }

    &__text {
      max-width: 720px;
      text-align: right;
      margin: 0 0 0 auto;

      .heading {
        font-weight: 500;
        line-height: 1.17;
        font-size: 2.3rem;
        font-family: ${larsseit};
        margin: 0 0 20px 0;
        max-width: 600px;
        @media ${screen.xsmall} {
          margin: 0 0 20px auto;
        }
        @media ${screen.small} {
          font-size: 2.9rem;
          margin: 0 0 20px 0;
          max-width: none;
        }
        @media ${screen.medium} {
          font-size: 3rem;
        }
        @media ${screen.xlarge} {
          margin: 0 0 30px 0;
        }
      }

      .description {
        font-size: 1.1rem;
        @media ${screen.small} {
          font-size: 1.15rem;
        }
        @media ${screen.medium} {
          font-size: 1.33rem;
        }
      }
    }

    &__magazines {
      display: flex;

      .img {
        margin: 38px 0 135px 0;
        @media ${screen.xsmall} {
          margin: 155px 0 0 0;
        }

        &--left {
          display: none;
          @media ${screen.xsmall} {
            display: block;
            width: 33%;
            margin: 55px 0 0 0;
          }
          @media ${screen.small} {
            margin: 0;
          }
        }

        &--right {
          position: relative;
          width: 100%;
          @media ${screen.xsmall} {
            right: -36px;
            width: 67%;
          }
          @media ${screen.small} {
            right: -40px;
          }
          @media ${screen.medium} {
            right: -55px;
          }

          .pointer {
            position: absolute;
            display: flex;
            right: 13px;
            bottom: -55px;
            @media ${screen.xsmall} {
              top: -52px;
              right: 255px;
              bottom: auto;
            }
            @media ${screen.small} {
              top: -42px;
            }

            svg {
              width: 40px;
              transform: rotate(-90deg) scaleX(-1);
              display: block;
              position: absolute;
              left: -66px;
              top: -35px;
              @media ${screen.xsmall} {
                transform: rotate(80deg) scaleX(-1);
                width: 57px;
                top: -10px;
                right: -90px;
                left: auto;
              }
            }

            p {
              color: ${mainBlack};
              font-size: 1.2rem;
              font-weight: 700;
              line-height: 1.3;
              text-transform: uppercase;
              font-family: ${amatic};
              @media ${screen.xsmall} {
                font-size: 1.55rem;
              }
            }
          }
        }
      }
    }
  }

  .next-btn {
    display: flex;
    justify-content: center;
    padding: 0 0 35px 0;
    @media ${screen.small} {
      padding: 0 0 70px 0;
    }
  }
`;

const AustralianThermicLancePage = ({ data }) => {
  const url = typeof window !== "undefined" && window.location.href;

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTopImg, setIsTopImg] = useState(false);

  const [clientX, setClientX] = useState(385);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [innerWidth, setInnerWidth] = useState(
    typeof window !== "undefined" && window.innerWidth
  );

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    swipe: true,
    draggable: false,
    speed: 750,
    arrows: false,
    autoplaySpeed: 7000,
    pauseOnHover: false,
    easing: `linear`,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const handleMouseMove = (e) => {
    setClientX(e.clientX);
    if (!isMouseOver) {
      setIsMouseOver(true);
    }
  };

  let changeSlide;

  useEffect(() => {
    const resizing = (e) => {
      setInnerWidth(e.target.innerWidth);
    };
    window.addEventListener("resize", resizing, { passive: true });

    return () => {
      window.removeEventListener("resize", resizing, { passive: true });
    };
  }, []);

  const overWidth = innerWidth > 1919 ? (innerWidth - 1920) / 2 : 0;

  return (
    <Layout colourNavbar={mainWhite}>
      <SEO
        title="Australian Thermic Lance Company | Handsome Creative"
        description="ATLC are one of the leading manufactures of Thermic Lances, because of sizes available, accessories, safety practices and efficiency. Because they can deliver thousands of Lances within hours, customers have seen dramatic downtime reductions for many furnaces with molten metal spills."
        image={imgPrint}
        webPageInfo={{
          name: "Australian Thermic Lance Company by Handsome Creative",
          url,
          description:
            "ATLC are one of the leading manufactures of Thermic Lances, because of sizes available, accessories, safety practices and efficiency. Because they can deliver thousands of Lances within hours, customers have seen dramatic downtime reductions for many furnaces with molten metal spills.",
        }}
      />
      <Wrapper
        clientX={clientX}
        innerWidth={innerWidth}
        isMouseOver={isMouseOver}
        imgWidth={innerWidth - clientX - overWidth}
        isFirstSlide={currentSlide === 0}
        isTopImg={isTopImg}
      >
        <ScrollHint />
        <div className="main">
          <h1 className="main__heading">Australian Thermic Lance Company</h1>
          <h6 className="main__subheading">
            Branding, web design, printed collateral
          </h6>
          <div className="main__description">
            <p>
              Hundreds of businesses rely on Australian Thermic Lance Company
              (ATLC) with their ability to hold large stocks of Lances to meet
              any emergency. ATLC are one of the leading manufactures of Thermic
              Lances, because of sizes available, accessories, safety practices
              and efficiency. Because they can deliver thousands of Lances
              within hours, customers have seen dramatic downtime reductions for
              many furnaces with molten metal spills.
            </p>
            <p>
              With 46 years in business, ATLC needed help to update their
              identity to reflect competitive advantage and market position as
              manufacturing leaders. With Handsome Creative, ATLC's new bold
              logo, fresh colour palette and new typography guidelines were
              brought together and applied to a unique eCommerce website,
              product information flyers and magazine spread within two months.
              Upon the new website going live, the SEO score hit a 98% high, and
              a 97% performance score.
            </p>
          </div>

          <a
            className="main__web-link"
            href="https://www.thermiclance.com.au/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit company website"
            title="ATLC website"
          >
            thermiclance.com.au
          </a>
        </div>
        <Waypoint
          onEnter={() => {
            if (!isTopImg) {
              setIsTopImg(true);
            }
          }}
          topOffset={100}
        >
          <div className="top-featured-img">
            <div className="img-subwrapper">
              <StaticImage
                src="../../images/case-studies/australian-thermic-lance/handsome-creative-ATLC-logo-design.png"
                alt="Australian Thermic Lance connect with their customers more with Handsome."
              />
            </div>
          </div>
        </Waypoint>

        <section className="branding">
          <div className="branding__flex">
            <div className="col col--video">
              <div data-sal="slide-up" data-sal-duration="500">
                <video playsInline loop={true} autoPlay="autoplay" muted>
                  <source src={videoLogo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <div className="col col--text">
              <h3
                className="heading"
                data-sal="slide-up"
                data-sal-duration="500"
              >
                ATLC celebrates 46 Years of successful business.
              </h3>
              <p
                className="description"
                data-sal="slide-up"
                data-sal-duration="500"
              >
                Taking a simplified graphic of a thermic lance and moulding it
                with bold lettering allowed Handsome to design a similar logo to
                the original to be recognisable, but with enough uniqueness to
                give the brand a fresh new look and feel.
              </p>
            </div>
          </div>
        </section>

        <section className="logo">
          <LogoProcessLg
            className="lg"
            data-sal="slide-up"
            data-sal-duration="500"
          />
          <LogoProcessSm
            className="sm"
            data-sal="slide-up"
            data-sal-duration="500"
          />
        </section>

        <section className="site-carousel">
          <Slider
            ref={(slider) => (changeSlide = slider && slider.slickGoTo)}
            {...settings}
          >
            <div className="each-slide each-slide--one">
              <div className="flex" data-sal="slide-up" data-sal-duration="500">
                <div className="flex__logo">
                  <div className="pointer">
                    <ArrowCurved />
                    <p>
                      Final, full-colour
                      <br />
                      logo design
                    </p>
                  </div>
                  <LogoATLC />
                </div>

                <div className="flex__gif">
                  <div className="gif-subwrapper">
                    <img src={gifPhone} alt="Handsome Creative ATLC Design" />
                    <div className="pointer">
                      <ArrowCurved />
                      <p>
                        Responsive design to ensure
                        <br />
                        the site looks great across
                        <br />
                        all devices
                      </p>
                    </div>
                  </div>
                </div>

                <div></div>
              </div>
            </div>
            <div className="each-slide each-slide--two">
              <div className="flex">
                <div className="flex__text">
                  <h3 className="heading">
                    Increased demand for user experience.
                  </h3>
                  <p className="description">
                    Users digest and navigate through information differently.
                    Handsome worked closely with the ATLC team to create a
                    website that is informative and intuitive to navigate.
                  </p>
                </div>
                <div className="flex__img">
                  <figure className="img-subwrapper">
                    <img src={carouselGif2} alt="Designed to inform" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="each-slide each-slide--three">
              <div className="flex">
                <div className="flex__text">
                  <h3 className="heading">
                    Richer insights and personalised service for customers.
                  </h3>
                  <p className="description">
                    As part of its response to capture buying insights and make
                    it easier to buy, Handsome included a unique eCommerce
                    element. A product enquiry functionality acts as a shopping
                    cart. Customers can receive detailed quotes and information
                    about the best distributor to contact.
                  </p>
                </div>
                <div className="flex__img">
                  <figure className="img-subwrapper">
                    <img
                      src={carouselGif3}
                      alt="Personalised service for customers"
                    />

                    <div className="pointer">
                      <ArrowCurved />
                      <p>
                        A Specialised ecommerce
                        <br />
                        experience to assist
                        <br />
                        customers in purchasing
                        <br />
                        the correct product.
                      </p>
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </Slider>

          <div className="dots">
            <span
              onClick={() => changeSlide(0)}
              className={
                currentSlide === 0 ? "dots__dot dots__dot--active" : "dots__dot"
              }
            ></span>
            <span
              onClick={() => changeSlide(1)}
              className={
                currentSlide === 1 ? "dots__dot dots__dot--active" : "dots__dot"
              }
            ></span>
            <span
              onClick={() => changeSlide(2)}
              className={
                currentSlide === 2 ? "dots__dot dots__dot--active" : "dots__dot"
              }
            ></span>
          </div>
        </section>

        <section className="seo">
          <div className="seo__flex">
            <div
              className="col col--text"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <h3 className="heading">
                98% SEO score & 97% performance score at website launch.
              </h3>
            </div>
            <div
              className="col col--gif"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <img src={seoGif} alt="Australian Thermic Lance SEO score" />
            </div>
          </div>

          <div className="seo__url">
            <a
              href="https://thermiclance.com.au"
              target="_blank"
              rel="noreferrer"
              data-sal="slide-up"
              data-sal-duration="500"
              title="ATLC Website"
            >
              thermiclance.com.au
            </a>
          </div>
        </section>

        <section className="flyer">
          <div className="flyer__flex">
            <div className="col col--text">
              <div
                className="text-subwrapper"
                data-sal="slide-up"
                data-sal-duration="500"
              >
                <h3 className="heading">
                  ATLC modernises its brand to adapt to meet customers' changing
                  needs.
                </h3>
                <p className="description">
                  Regardless of how your customers choose to interact with your
                  brand, they should experience consistency across all
                  touchpoints. Not only did Handsome work with ATLC to create a
                  fresh new vibe, but they also applied this across all of their
                  sales resources.
                </p>
              </div>
            </div>

            <div
              className="col col--slide-img"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <div
                className="slide-wrapper slide-wrapper--interactive"
                onMouseMove={handleMouseMove}
              >
                <div className="img img--one"></div>
                <div className="img img--two"></div>

                <div className="lined-arrow">
                  <ArrowSideToSide />
                </div>
              </div>

              <div className="slide-wrapper slide-wrapper--static">
                <StaticImage src="../../images/case-studies/australian-thermic-lance/handsome-creative-ATLC-print-design.jpg" />
              </div>
            </div>
          </div>
        </section>

        <section className="print">
          <div
            className="print__text"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <h3 className="heading">
              ATLC in print to support global business sales strategy.
            </h3>
            <p className="description">
              Handsome supported the launch further through print advertising
              designs via First Mining DRC Magazine in Zambia. Using the brand
              style previously created, Handsome designed a simple page to
              inform readers about ATLC and their offerings.
            </p>
          </div>
          <div
            className="print__magazines"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <div className="img img--left">
              <StaticImage
                src="../../images/case-studies/australian-thermic-lance/australian-thermic-lance-print.png"
                alt="Handsome Creative Page - Australian Thermic Lance Print"
              />
            </div>
            <div className="img img--right">
              <StaticImage
                src="../../images/case-studies/australian-thermic-lance/handsome-creative-print-australian-thermic-lance.png"
                alt="Handsome Creative Page - Australian Thermic Lance Print"
              />
              <div className="pointer">
                <ArrowCurved />
                <p>This is the page we designed!</p>
              </div>
            </div>
          </div>
        </section>

        <div className="next-btn">
          <LabeledArrow
            label="SHOW ME ANOTHER PROJECT"
            url="/our-work/source-separation-systems/"
          />
        </div>
        <ContactForm />
      </Wrapper>
    </Layout>
  );
};

export default AustralianThermicLancePage;
